import React from "react";
import "./Home.scss";
import { Input, Row, Col, Layout } from 'antd';
import { Link } from "react-router-dom";

import Navbar from "../../molecules/Header/Header";
import Footer from "../../molecules/Footer/Footer";
import Contact from "../Contact/Contact";

import BarGraph from "./assets/images/BarsImage.png";
import HomeImg1 from "./assets/images/homeImg1.png";
import HomeImg2 from "./assets/images/homeImg2.png";
import HomeImg3 from "./assets/images/homeImg3.png";
import Icon1 from "./assets/icons/icon1.png";
import Icon2 from "./assets/icons/icon2.png";
import Icon3 from "./assets/icons/icon3.png";
import Icon4 from "./assets/icons/icon4.png";
import Icon5 from "./assets/icons/icon5.png";
import Icon6 from "./assets/icons/icon6.png";
import Icon7 from "./assets/icons/icon7.png";
import Icon8 from "./assets/icons/icon8.png";


const { Header, Content } = Layout;

const Home = () => {
  return (
    <Layout style={{ background: "transparent" }}>
      <Navbar selectedKey="1" />
      <Content style={{ marginTop: 64 }}>
        <div className="home">
          <div className="homeDiscover">
            <Row className="homeHeader">
              <Col xl={12} className="heading">
                <div style={{ width: "95%" }}>
                  <h1>We’ll help you <span className="headingSpan">discover</span> the trends that matter</h1>
                  <p>
                    Bolster your decision-making with competitive insights into the newest trends, competitors' performance and market opportunities. Drive sales, increase margins and remain ahead of the competition.
                  </p>
                </div>
                <div className="demoInput">
                  <Input
                    className="demoEmail"
                    placeholder="Enter email..."
                    size="large"
                  />
                  <Link to="./demo">
                    <button className="demoBookBtn">Book a Demo</button>
                  </Link>
                </div>
              </Col>
              <Col xl={12} className="headerImg">
                <div className="image">
                  <img src={BarGraph} alt="" />
                </div>
              </Col>
            </Row>
          </div>

          <section className="retailSection">
            <div className="retail">
              <div className="retailInner">
                <div className="retailHeading">
                  <h2>The <span>retail landscape</span> is changing</h2>
                  <p>
                    Today, consumers have more choices available to them than ever before. Their ever-changing tastes mastermind the rise of new trends and the sudden fall of others. Leading the charge in this fast-paced environment requires overarching market intelligence. That’s where we come in.
                  </p>
                </div>

                <Row gutter={[20, 20]} className="retailContent">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="retailCol">
                    <div className="part1">
                      <div className="retailLine"></div>
                      <h4>
                        Expert strategies tailored for your brand
                      </h4>
                      <p>
                        Resolve your most challenging business problems with comprehensive reports from our team of expert data scientists with access to raw market data
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="retailCol">
                    <div className="part1">
                      <img src={HomeImg2} alt="" />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="retailCol">
                    <div className="part1">
                      <img src={HomeImg1} alt="" />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="retailCol">
                    <div className="part1">
                      <div className="retailLine"></div>
                      <h4>
                        Real-time information about customers and competitors
                      </h4>
                      <p>
                        Trends Sight collects data from thousands of brands worldwide daily, giving you a clear look at rising trends and competitor product and pricing information
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
          <section className="whatWEDo_Section">
            <div className="whatWEDo">
              <div className="whatWEDo_heading">
                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Col xs={3} sm={5} md={4} lg={6} xl={6}>
                    <hr />
                  </Col>
                  <Col xs={18} sm={14} md={12} lg={8} xl={6}>
                    <h1 style={{ textAlign: "center" }}>What We Do</h1>
                  </Col>
                  <Col xs={3} sm={5} md={4} lg={6} xl={6}>
                    <hr />
                  </Col>
                </Row>
                <Row gutter={[40, 40]} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "60px", maxWidth: "75rem", margin: "0 auto" }}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="cardCOl">
                    <div className="whatWEDo_card">
                      <img src={Icon1} alt="" />
                      <h4>
                        Management
                      </h4>
                      <p>
                        We provide MANAGEMENT with optimal solutions that drive sales and brand growth. Keep profits on the rise to hit all your sales targets and outdo the competition
                      </p>

                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="cardCOl">
                    <div className="whatWEDo_card">
                      <img src={Icon2} alt="" />
                      <h4>
                        Designers
                      </h4>
                      <p>
                        We help DESIGNERS identify working product designs that captivate customers. Design and launch in style products that appeal to your customers with our unparalleled data-driven fashion insights
                      </p>

                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="cardCOl">
                    <div className="whatWEDo_card">
                      <img src={Icon3} alt="" />
                      <h4>
                        Buyers & Merchandisers
                      </h4>
                      <p>
                        We help BUYERS & MERCHANDISERS identify the best pricing strategies for excellent sales performance all year round. At just the click of a button, view real-time market insights and consistently hit all your sales targets
                      </p>

                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="cardCOl">
                    <div className="whatWEDo_card">
                      <img src={Icon4} alt="" />
                      <h4>
                        Executives
                      </h4>
                      <p>
                        We help EXECUTIVES reinforce business plans with solid strategies to drive growth. Make sound, defensible brand strategies that drive sales and demand, boosting growth and investor confidence
                      </p>

                    </div>
                  </Col>
                </Row>

              </div>
            </div>
          </section>
          <section className="reportsSection">
            <div className="reports">
              <div className="reportsInner">
                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Col xs={3} sm={5} md={4} lg={6} xl={6}>
                    <hr />
                  </Col>
                  <Col xs={18} sm={14} md={12} lg={8} xl={6}>
                    <h1 style={{ textAlign: "center" }}>Our Reports</h1>
                  </Col>
                  <Col xs={3} sm={5} md={4} lg={6} xl={6}>
                    <hr />
                  </Col>
                  <Col span={24}>
                    <div className="reportsHeadingPara">
                      <p>
                        Trends Sight's competitive insights cover everything you need to know about the ever-changing market. Be the first to identify emerging regional and international trends, as well as important retail calendar dates with the click of a button. Comfortably review your competitors' product and pricing information on our user-friendly insights dashboards.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row gutter={[80, 80]} style={{ marginTop: "40px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="reportsImg">
                      <img src={HomeImg3} alt="" />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} className="reportText">
                        <div>
                          <div className="reportsLine"></div>
                          <h2>World-class Data science team</h2>
                          <p>
                            Our in-house team of experts are Ph.D.'s in data science, with many years of experience in analytics and visualization, artificial intelligence, machine learning, probability and statistics, and natural language processing
                          </p>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} className="reportText">
                        <div>
                          <div className="reportsLine"></div>
                          <h2>Reliable data guaranteed Highest quality, complete coverage.</h2>
                          <p>
                            Our data is reliable, up-to-date, and customizable, down to the last digits. Access billions of data points that cover hundreds of thousands of brands worldwide without leaving your office.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </section>

          <section className="teamSection">
            <div className="team">
              <div className="teamInner">
                <div className="teamHeading">
                  <h2>Our <span>support team</span> of industry experts on hand to assist you</h2>
                  <p>
                    Track billions of SKUs daily from brands the world over with our advanced AI and revamp your marketing strategies in real-time with assortment, pricing, and promotion benchmarking, all from the comfort of your workspace
                  </p>
                </div>
                <Row gutter={[60, 60]} className="teamCards">
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <img src={Icon5} alt="" />
                    <h5>No Integration</h5>
                    <p>Connect directly in a matter of minutes, no setup or integration required.</p>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <img src={Icon6} alt="" />
                    <h5>Direct Support</h5>
                    <p>Fast and direct support available, phone, zoom or email, whatever your preference.</p>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <img src={Icon7} alt="" />
                    <h5>Global Coverage</h5>
                    <p>Access data in any global market. We guarantee both availability and quality.</p>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <img src={Icon8} alt="" />
                    <h5>Data Scientists</h5>
                    <p>Every business has unique questions that need to be answered. Rely on our Data Scientists to find those answers.</p>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
          <section className="bookingSection">
            <div className="booking">
              <h2>Are you <span>ready</span> to start?</h2>
              <p>
                Track billions of SKUs daily from brands the world over with our advanced AI and revamp your marketing strategies in real-time with assortment, pricing, and promotion benchmarking, all from the comfort of your workspace
              </p>
              <Row style={{ width: "75%", margin: "0 auto" }}>
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                  <Input placeholder="Enter your email..." className="bookingMail" />
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Link to="./demo">
                    <button className="bookingBtn">Book a Free Demo</button>
                  </Link>
                </Col>
              </Row>
            </div>
          </section>
          <div className="footerAttach"></div>
        </div>
      </Content>
      <Footer />
      <Contact />
    </Layout>
  );
};

export default Home;
