import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import "./Contact.scss";

import Envelope from "./assets/images/envelope.svg";
import Cross from "./assets/images/cross.svg";
import { MailOutlined } from "@ant-design/icons";

const Contact = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="contact">
            <Button type="primary" className={`contact_fixed ${isModalVisible ? 'hide' : 'show'}`} onClick={showModal}>
                <img className="envelope" src={Envelope} alt={''} />
                <span className="text">Message</span>
            </Button>
            <Modal className="modal"
                footer={null}
                title={
                    <div className="modalTitle">
                        <div className="round_envelope">
                            <img className="envelope" src={Envelope} alt={''} />
                        </div>
                        <span className="text">Contact</span>
                    </div>
                }
                visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>

                <form action="">
                    <Row justify="space-between">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="formCol">
                            <label htmlFor="text">Name</label>
                            <input type="text" placeholder="Enter your Name" />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={11} xl={11} className="formCol">
                            <label htmlFor="text">Email</label>
                            <input type="email" placeholder="Enter your Email" />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={11} xl={11} className="formCol">
                            <label htmlFor="text">Telephone</label>
                            <input type="number" placeholder="Enter your contact number" />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="formCol">
                            <label htmlFor="text">Message</label>
                            <textarea name="message" placeholder="How can we help you?"></textarea>
                        </Col>
                    </Row>
                    <div className="submitBtn">
                        <a href="">Contact Us</a>
                    </div>
                </form>

            </Modal>
        </div>
    );
};

export default Contact;
