import React from "react";
import "./BlueButton.scss";

export interface BlueButtonProps {
  customCls?: string;
  label: string;
  onClick?: () => void;
}

const BlueButton = ({
  customCls,
  label,
  onClick = () => {},
}: BlueButtonProps) => {
  return (
    <div
      className={`blue_button ${customCls}`}
      onClick={() => {
        onClick();
      }}
    >
      {label}
    </div>
  );
};

export default BlueButton;
