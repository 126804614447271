export const PrivacyPolicy_Texts = {
  Heading: "Privacy Policy for Trends Sight",
  SubHeading:
    "",
  Heading_One:
    "PLEASE READ THIS POLICY CAREFULLY BEFORE USING TRENDS SIGHTS LTD's SERVICES",
  SubHeading_One:
    "This Privacy Policy (together with our Website terms of use at https://edited.com/terms/), sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by EDITED. Please read this privacy policy carefully to understand the types of information we collect from you, how we use that information, the circumstances under which we will share it with third parties, and your rights in relation to the personal data you provide to us. By using our services, you accept the terms of this privacy policy. The Privacy Policy does not apply to any third-party website, software or applications that integrate with our services (together, the “Third-Party Services”). The Third-Party Services have their own privacy policies and we do not accept any responsibility or liability for their policies or processing of your personal information. Please check these policies before you submit any personal information to such any Third-Party Services.",
  Heading_Two: "Information we may collect",
  SubHeading_Two:
    "We are committed to protecting your privacy. For further information about the type of data we collect, how we use your personal data and further details about the processing of your personal data, please refer to our Privacy Policy.",
  Heading_Three: "How we use your information and justification of use",
  SubHeading_Three:
    "We are registered under the Data Protection Act 1998 and as such, any information concerning the Client and their respective Client Records may be passed to third parties. However, Client records are regarded as confidential and therefore will not be divulged to any third party, other than if legally required to do so to the appropriate authorities. Clients have the right to request sight of, and copies of any and all Client Records we keep, on the proviso that we are given reasonable notice of such a request. Clients are requested to retain copies of any literature issued in relation to the provision of our services. Where appropriate, we shall issue Client’s with appropriate written information, handouts or copies of records as part of an agreed contract, for the benefit of both parties.\n\n We will not sell, share, or rent your personal information to any third party or use your e-mail address for unsolicited mail from third parties. Any emails sent by this Company will only be in connection with the provision of agreed services and products.",
  Heading_Four: "Where we store and how we protect your personal information",
  SubHeading_Four:
    "Exclusions and Limitations – The information on this web site is provided on an “as is” basis. To the fullest extent permitted by law, this Company: excludes all representations and warranties relating to this website and its contents or which is or may be provided by any affiliates or any other third party, including in relation to any inaccuracies or omissions in this website and/or the Company’s literature; and excludes all liability for damages arising out of or in connection with your use of this website. This includes, without limitation, direct loss, loss of business or profits (whether or not the loss of such profits was foreseeable, arose in the normal course of things or you have advised this Company of the possibility of such potential loss), damage caused to your computer, computer software, systems and programs and the data thereon or any other direct or indirect, consequential and incidental damages.\n\n This Company does not however exclude liability for death or personal injury caused by its negligence. The above exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer are affected.",
  Heading_Five: "Disclosure of your information",
  SubHeading_Five:
    "We are committed to protecting your privacy. For further information about the type of data we collect, how we use your personal data and further details about the processing of your personal data, please refer to our Privacy Policy.",
  Heading_Six: "How long we retain your personal data",
  SubHeading_Six:
    "We are registered under the Data Protection Act 1998 and as such, any information concerning the Client and their respective Client Records may be passed to third parties. However, Client records are regarded as confidential and therefore will not be divulged to any third party, other than if legally required to do so to the appropriate authorities. Clients have the right to request sight of, and copies of any and all Client Records we keep, on the proviso that we are given reasonable notice of such a request. Clients are requested to retain copies of any literature issued in relation to the provision of our services. Where appropriate, we shall issue Client’s with appropriate written information, handouts or copies of records as part of an agreed contract, for the benefit of both parties.\n\n We will not sell, share, or rent your personal information to any third party or use your e-mail address for unsolicited mail from third parties. Any emails sent by this Company will only be in connection with the provision of agreed services and products.",
  Heading_Seven: "Contact",
  SubHeading_Seven:
    "Exclusions and Limitations – The information on this web site is provided on an “as is” basis. To the fullest extent permitted by law, this Company: excludes all representations and warranties relating to this website and its contents or which is or may be provided by any affiliates or any other third party, including in relation to any inaccuracies or omissions in this website and/or the Company’s literature; and excludes all liability for damages arising out of or in connection with your use of this website. This includes, without limitation, direct loss, loss of business or profits (whether or not the loss of such profits was foreseeable, arose in the normal course of things or you have advised this Company of the possibility of such potential loss), damage caused to your computer, computer software, systems and programs and the data thereon or any other direct or indirect, consequential and incidental damages.\n\n This Company does not however exclude liability for death or personal injury caused by its negligence. The above exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer are affected.",
};

export const CookiePolicy_Texts = {
  Heading: "Cookie Policy for Trends Sight",
  SubHeading:
    "",
  Heading_One:
    "Cookie Policy",
  SubHeading_One:
    "",
  Heading_Two: "",
  SubHeading_Two:
    "",
  Heading_Three: "",
  SubHeading_Three:
    "",
  Heading_Four: "",
  SubHeading_Four:
    "",
  Heading_Five: "",
  SubHeading_Five:
    "",
  Heading_Six: "",
  SubHeading_Six:
    "",
  Heading_Seven: "",
  SubHeading_Seven:
    "",
};

export const DataProcessing_Texts = {
  Heading: "Data Processing for Trends Sight",
  SubHeading:
    "We at Trends Sight are committed to protecting your privacy. This Product Privacy Policy applies to the data Trends Sight collects and processes on behalf of our Internal Intelligence customers (Ecommerce Data) in our capacity as a service provider, and describes how we access, use, store and share your Ecommerce Data.",
  Heading_One:
    "Data Processing",
  SubHeading_One:
    "This Product Privacy Policy does not apply to any personal information or data collected by Trends Sight for other purposes, such as information collected on our websites or through other channels for marketing purposes. Please find the Trends Sight Privacy Policy that covers this information or data here.",
  Heading_Two: "Internal Intelligence Service",
  SubHeading_Two:
    "Our Internal Intelligence Service (Service) enhances our users’ ecommerce data with a comprehensive fashion product taxonomy, before generating detailed analysis, insights, reports and call-to-action recommendations, to help the users make better decisions to maximise sales and increase online store conversion.",
  Heading_Three: "Ecommerce Data that we collect",
  SubHeading_Three:
    "Trends Sight collects a variety of Ecommerce Data when you provide it to us, when you use our Service, or when other sources provide it to us. These information falls into these categories:",
  Heading_Four: "Customer’s online stores (e.g. Shopify, WooCommerce)",
  SubHeading_Four:
    "We do not collect or store personal data relating to our customer’s customers, such as first and last name, email address, physical address, or phone number. Trends Sight does not control the content of our customer’s webpages or the types of Personal Data that our customers may choose to collect. That Personal Data is controlled by them and is used, disclosed and protected by them according to their privacy policies.Customers are responsible for complying with any regulations or laws that require providing notice, disclosure, and/or obtaining consent prior to transferring Ecommerce Data to Ominilytics for processing purposes.We have no direct relationship with individuals who provide Personal Information to our customers. Our customers control and are responsible for correcting, deleting or updating information they have collected.",
  Heading_Five: "Access to Ecommerce Data",
  SubHeading_Five:
    "Trends Sight collects the Ecommerce Data you provide to us expressly or implicitly when you do things such as sign up for the Service, and set up authentication to allow Trends Sight to access your accounts and your Ecommerce Data via other service providers.",
  Heading_Six: "Use of Ecommerce Data",
  SubHeading_Six:
    "Trends Sight uses the collected Ecommerce Data for various purposes:\n\n Trends Sight uses the collected Ecommerce Data for various purposes:\n\nTo provide & maintain our Service\nTo notify you about changes to our Service\nTo allow you to participate in interactive features of our Service when you choose to do so\nTo personalize your experience using our Service\nTo provide customer support\nTo gather analysis so that we can improve our Service\nTo monitor the usage of our Service\nTo detect, prevent and resolve technical issues\nStoring of Ecommerce Data\nTrends Sight stores your Ecommerce Data in a secure managed cloud-based database and all the data will be encrypted at rest. We store only the data that is absolutely necessary for the Service to function in its entirety. Deleting some of this data will interrupt the Service’s functionality, and impact your usage.\n If you cease to be an Internal Intelligence Customer, we will remove your Ecommerce Data upon 30 days of your ceasing to be an Internal Intelligence Customer or upon receipt by us of your request to info [at] trendssight [dot] com to delete the data, whichever comes first.",
  Heading_Seven: "Sharing of Ecommerce Data",
  SubHeading_Seven:
    "Trends Sight will never transfer, sell, make copies, or share any of your Ecommerce Data to third party services or companies.\nWe will only share these Ecommerce Data under these circumstances:\nDue to Corporate Events\n\nIf Trends Sight (or Omniytics’ assets) are acquired by another company, whether by merger, acquisition, bankruptcy or otherwise, that company would receive all information gathered by Trends Sight on the Internal Intelligence Service. We will notify you via email of any change in ownership.\n\nIn accordance with Compelled Disclosure\n\nTrends Sight reserves the right to use or disclose your Ecommerce Data if required by law or if we reasonably believe that use or disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or comply with a law, court order, or legal process.\n\nWe periodically update this Product Privacy Policy. We will post any changes on this page and, if the changes are material, we will provide an update through email.",
};

