import React from "react";
import "./Header.scss";
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.css';
import { Link } from "react-router-dom";
import {
  UserOutlined

} from '@ant-design/icons';

import trendLogo from "../../../assets/images/LogoNew.png";


const { Header, Content, Footer } = Layout;

type defaultSelected = {
  selectedKey: string
}

const Navbar = ({ selectedKey }: defaultSelected) => {
  return (
    <>

      <div style={{ position: 'fixed', zIndex: 1, display: "flex", justifyContent: "center", background: "#FFFFFF", width: "100%", boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)" }}>
        <Header>
          <div className="header">
            <div className="logo">
              <img src={trendLogo} alt="" />
            </div>
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[selectedKey]}>
              <Menu.Item key="1" className="headerLinks">
                <Link to="./home">
                  <span>Home</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="2" className="headerLinks">
                <Link to="./solution">
                  <span>Solution</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="3" className="headerLinks">
                <Link to="./sustainability">
                  <span>Sustainability</span>
                </Link>
              </Menu.Item>
            </Menu>

            <div>
              <Link to="./login">
                <UserOutlined className="loginOpen"/>
              </Link>
            </div>
          </div>
        </Header>
      </div>

    </>
  );
};

export default Navbar;
