import React, { useState } from "react";
import "./TabSelector.scss";
import { TAB_SELECTOR } from "../../types/index";

export interface TabSelectorProps {
  customCls?: string;
  selectedTab?: TAB_SELECTOR;
  onClick?: (tab:TAB_SELECTOR) => void;
}

const TabSelector = ({
  customCls,
  selectedTab = TAB_SELECTOR.MERCHANDISERS,
  onClick = () => {},
}: TabSelectorProps) => {
  const [selectedTabState, setselectedTabState] = useState(
    TAB_SELECTOR.MERCHANDISERS
  );

  const switchTab = (tab: TAB_SELECTOR) => {
    setselectedTabState(tab);
    onClick(tab);
  };

  return (
    <div className={`tab_selector ${customCls}`}>
      <span
        onClick={() => {
          switchTab(TAB_SELECTOR.DESIGNER);
        }}
        className={`tab_ designer ${
          selectedTabState === TAB_SELECTOR.DESIGNER ? "selected" : ""
        }`}
      >
        {TAB_SELECTOR.DESIGNER}
        {selectedTabState === TAB_SELECTOR.DESIGNER ? (
          <div className={"blue_bar"}></div>
        ) : (
          ""
        )}
      </span>
      <span
        onClick={() => {
          switchTab(TAB_SELECTOR.MANAGEMENT);
        }}
        className={`tab_ management ${
          selectedTabState === TAB_SELECTOR.MANAGEMENT ? "selected" : ""
        }`}
      >
        {TAB_SELECTOR.MANAGEMENT}
        {selectedTabState === TAB_SELECTOR.MANAGEMENT ? (
          <div className={"blue_bar"}></div>
        ) : (
          ""
        )}
      </span>
      <span
        onClick={() => {
          switchTab(TAB_SELECTOR.MERCHANDISERS);
        }}
        className={`tab_ merchandisers ${
          selectedTabState === TAB_SELECTOR.MERCHANDISERS ? "selected" : ""
        }`}
      >
        {TAB_SELECTOR.MERCHANDISERS}
        {selectedTabState === TAB_SELECTOR.MERCHANDISERS ? (
          <div className={"blue_bar"}></div>
        ) : (
          ""
        )}
      </span>
    </div>
  );
};

export default TabSelector;
