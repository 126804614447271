import React, { useState } from 'react';
import './Solution.scss';
import { Tabs, Row, Col, Layout } from 'antd';

import Navbar from "../../molecules/Header/Header";
import Footer from "../../molecules/Footer/Footer";
import Contact from "../Contact/Contact";

import de1 from './assets/images/designer/img1.png';
import de2 from './assets/images/designer/img2.png';
import de3 from './assets/images/designer/img3.png';
import de4 from './assets/images/designer/img4.png';
import de5 from './assets/images/designer/img5.png';
import de6 from './assets/images/designer/img6.png';
import de7 from './assets/images/designer/img7.png';
import de8 from './assets/images/designer/img8.png';

import mg1 from './assets/images/Management/img1.png';
import mg2 from './assets/images/Management/img2.png';
import mg3 from './assets/images/Management/img3.png';
import mg4 from './assets/images/Management/img4.png';
import mg5 from './assets/images/Management/img5.png';
import mg6 from './assets/images/Management/img6.png';
import mg7 from './assets/images/Management/img7.png';

import me1 from './assets/images/Merchandiser/img1.png';
import me2 from './assets/images/Merchandiser/img2.png';
import me3 from './assets/images/Merchandiser/img3.png';
import me4 from './assets/images/Merchandiser/img4.png';

const { Header, Content } = Layout;

const { TabPane } = Tabs;


const Solution = () => {

  return (
    <Layout style={{ background: "transparent" }}>
      <Navbar selectedKey="2" />
      <Content style={{ marginTop: 64 }}>
        <section className="solutionSection">
          <div className="solution">
            <div className="sl-top-head">
              <Tabs centered>
                <TabPane tab="Designer" key="1" className="Designer">

                  <div className="de-head-one">
                    <h1>Attract customers with the right collections</h1>
                    <p>Design and launch assortments that appeal to your customers, at the right time, using real-time analytics</p>
                  </div>
                  <Row gutter={[60, 60]} justify="space-between" className="de-head-two">
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} className="de-two-card">
                      <img src={de1} alt="" />
                      <h3>Spot <span>trends</span> first</h3>
                      <p>Discover important trends before others, tailor your assortments to incorporate them, and become a market leader</p>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} className="de-two-card">
                      <img src={de2} alt="" />
                      <h3>Identify important <span>market gaps</span></h3>
                      <p>Obtain insights into fashion styles and patterns that are widely popular with your target customers and discover untapped opportunities</p>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} className="de-two-card">
                      <img src={de3} alt="" />
                      <h3>Drive sales with accurate <span>forecast</span> and timely launches</h3>
                      <p>Bolster your research with accurate trend analytics to make educated forecasts and design the right mix of assortments for your customers all year long</p>
                    </Col>

                  </Row>
                  <div style={{ background: 'white', padding: "10px" }}>

                    <div className="assortment">
                      <div className="assortmentHead">
                        <p className="assortPlanning">
                          ASSORTMENT PLANNING
                        </p>
                        <h2 className="assortHeading">
                          Draw up commercially-driven assortments plans that capture the trends and, in turn, your customers
                        </h2>
                        <p className="assortPara">
                          Adapt the latest trends into your assortments to meet the ever-growing customer demands in today's fast-moving retail market
                        </p>
                      </div>
                      <Row gutter={[40, 40]} className="assortCards">
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortText">
                            <h5>
                              <span>Craft assortments</span> that represent your market
                            </h5>
                            <p>
                              Do away with outdated styles and incorporate novel designs into your assortments to outdo the competition
                            </p>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortImg">
                            <img src={de4} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortImg">
                            <img src={de5} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortText">
                            <h5>
                              <span>Spot favourable trends</span> and ideal adjustments to your assortments
                            </h5>
                            <p>
                              Analyse rising trends, identify and ignore the fads, and incorporate the best tweaks and designs into your assortments on time to build customer loyalty
                            </p>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortText">
                            <h5>
                              Identify and fill <span>opportunity gaps</span> before the competition
                            </h5>
                            <p>
                              Review the competition's product categories and market performance and build a comprehensive assortment plan that factors in their missed opportunities and drives you ahead
                            </p>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortImg">
                            <img src={de6} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortImg">
                            <img src={de7} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortText">
                            <h5>
                              Drive sales and performance with a working <span>pricing strategy</span>
                            </h5>
                            <p>
                              Review the pricing architecture of your fiercest competitors and storm the market with a solid pricing strategy that’s sure to drive up your profit margin
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div style={{ background: "#FADCE4", padding: "10px" }}>
                    <div style={{ width: "70%", margin: "0 auto", padding: "60px 0px" }}>
                      <div className="planning">
                        <p className="pHead">Business Planning and Strategy</p>
                        <h2>
                          Reinforce business plans with solid strategies to drive growth
                        </h2>
                        <p className="planPara">
                          Reinforce your business plans with solid, data-backed strategies and grow your business to a new level
                        </p>
                      </div>
                    </div>

                  </div>
                  <div style={{ background: "white", padding: "10px" }}>
                    <div className="price">
                      <Row gutter={[40, 40]} className="priceBox">
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="priceImg">
                            <img src={de8} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="priceText">
                            <h4>
                              Stand at the forefront of <span>profitable trends</span>
                            </h4>
                            <p>
                              Spot rising trends before the competition, incorporate the favorable ones into your assortment, and impress local and international markets
                            </p>
                          </div>
                          <div className="priceText">
                            <h4>
                              Bolster your <span>business plan</span> and avoid marketing pitfalls
                            </h4>
                            <p>
                              Strengthen your business decisions with accurate information about the market and your competition, avoid retail pitfalls, elevate department strategies, and trade confidently
                            </p>
                          </div>
                          <div className="priceText">
                            <h4>
                              Adopt <span>novel strategies</span> to meet demand without fail
                            </h4>
                            <p>
                              Create a winning business strategy with detailed retail analytics that's aimed at meeting customers' expectations
                            </p>
                          </div>
                          <div className="priceText">
                            <h4>
                              Recognize threats and <span>grab opportunities</span> with ease
                            </h4>
                            <p>
                              Obtain a detailed overview of your market and draw up a blueprint to resist external threats, bolster your strengths and grab opportunities
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div style={{ background: "#E5DCFA", padding: "10px" }}>
                    <div className="trading">
                      <p className="tradingHead">
                        TRADING-REPORTING
                      </p>
                      <h2>
                        Speed up trading and act before the competition
                      </h2>
                      <p className="tradingPara">
                        Spot and fill market gaps fast, capitalize on trading opportunities, and consistently meet demand, all whilekeeping a close eye on the competition
                      </p>
                    </div>
                  </div>
                  <div style={{ background: "white", padding: "10px" }}>
                    <div className="shop">
                      <Row gutter={[60, 60]} className="shopInner">
                        <Col xs={24} sm={24} md={24} lg={10} xl={10} style={{ padding: '0' }}>
                          <div className="shopText">
                            <Row>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h5>
                                  <span>Comp shop</span> worldwide at the  click of a button
                                </h5>
                                <p>
                                  Manually sifting through competitor's websites is a tedious and outdated process. With Trends Sight we, get a hold of competitor's products with just a click and craft winning strategies
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h5>
                                  Find and take advantage of <span>profitable opportunities</span> right away
                                </h5>
                                <p>
                                  Not only will you be able to instantly identify profitable shifts in the market and determine the right trends to buy into with us, but you'll also be able toensure the right products are restocked on shelves and assigned the best prices
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h5>
                                  Benefit from identified <span>competitive blind-spots</span>
                                </h5>
                                <p>
                                  Our platform allows you to find competitive blind-spots to capitalise with winning pricing strategies that drive sales and ramp up demand
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10} style={{ padding: '0' }}>
                          <div className="shopImg">
                            <img src={mg7} alt="" />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                </TabPane>
                <TabPane tab="Management" key="2" className="Management">
                  <Row justify="center" className="mg-head-one">
                    <Col>
                      <h2>Boost sales and acquire robust market share</h2>
                      <p>Keep profits on the rise, meet your growth targets every time and cement yourself amongst market leaders</p>
                    </Col>
                  </Row>

                  <Row justify="space-around" className="mg-head-two">
                    <Col xs={24} sm={24} md={24} lg={10} xl={10} className="mg-two-part1">
                      <div className="mg-two-card">
                        <div className="mg-two-imgbox">
                          <img src={mg1} alt="" />
                        </div>
                        <h3>Identify and penetrate the <span>right markets</span> with ease</h3>
                        <p style={{ marginBottom: '40px' }}>Grow your business with up-to-date market insights and attract a greater audience</p>
                        <h3>Deliver creative offers and drive <span>brand loyalty</span></h3>
                        <p>Delight your customers with novelty and one-of-a-kind promotions introduced at the best times</p>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10} className="mg-two-part1" >
                      <div className="mg-two-card">
                        <div className="mg-two-imgbox">
                          <img src={mg2} alt="" />
                        </div>
                        <h3><span>Leap ahead</span> of the competition every time</h3>
                        <p style={{ marginBottom: '40px' }}>Identify market gaps first, capitalize on them with the right strategies, and gain incredible benefits as a first mover</p>
                        <h3>Arm your teams with the <span>right tools</span> for the job</h3>
                        <p>Improve your teams’ activities, from promotion to purchasing and design, with a unified data approach for optimum efficiency</p>
                      </div>
                    </Col>
                  </Row>

                  {/* launch section starts here */}

                  <div style={{ background: "white", padding: "10px" }}>
                    <div className="launchSection">
                      <div className="launchHeading">
                        <p className="launchHead">Newness Launch & promotion</p>
                        <h2>
                          Perfect your launch timing and meet your targets every time
                        </h2>
                        <p className="launchPara">
                          Stay in the race with access to real-time data of your market and know the perfect time to launch, promote, and markdown, meeting your targets every time
                        </p>
                      </div>
                      <Row className="launchCards">
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="launchText">
                            <h5>
                              Launch the <span>right products</span> for the right season
                            </h5>
                            <p>
                              Identify local and regional trends before others, and launch your assortments and promotions at the right time to enjoy first mover's advantage
                            </p>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="launchImg">
                            <img src={mg3} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="launchImg">
                            <img src={mg4} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="launchText">
                            <h5>
                              <span>Leave competitors</span> behind and trade with confidence
                            </h5>
                            <p>
                              Peruse your competitors' trade strategies and performance and craft long-term plans that perfect their weaknesses and clear your shelves of slow-moving inventory without hurting your profits
                            </p>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="launchText">
                            <h5>
                              Implement <span>promotions</span> that drive sales and boost profits
                            </h5>
                            <p>
                              Implement well-timed discounts that keep profits rolling in and leaves your competitors in the dust, your shelves free of aging inventory, and your liquidity at optimal levels
                            </p>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="launchImg">
                            <img src={mg5} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="launchImg">
                            <img src={mg6} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="launchText">
                            <h5>
                              Excite your customers with captivating <span>promotional messaging</span>
                            </h5>
                            <p>
                              Sift through  detailed reports of the competition's promotion strategies at the click of a button, and plan winning promotions that promote sales and appeal to customers
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div style={{ background: "#E9FADC", padding: "10px" }}>
                    <div className="assorPlan">
                      <p className="assorPHead">ASSORTMENT PLANNING</p>
                      <h2>
                        Draw up commercially-driven assortments plans that capture the trends and, in turn, your customers
                      </h2>
                      <p className="assorPPara">
                        Adapt the latest trends into your assortments to meet the ever-growing customer demands in today's fast-moving retail market
                      </p>
                    </div>
                  </div>
                  <div style={{ background: 'white', padding: "10px" }}>

                    <div className="assortment">
                      <Row className="assortCards">
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortText">
                            <h5>
                              <span>Craft assortments</span> that represent your market
                            </h5>
                            <p>
                              Do away with outdated styles and incorporate novel designs into your assortments to outdo the competition
                            </p>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortImg">
                            <img src={de4} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortImg">
                            <img src={de5} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortText">
                            <h5>
                              <span>Spot favourable trends</span> and ideal adjustments to your assortments
                            </h5>
                            <p>
                              Analyse rising trends, identify and ignore the fads, and incorporate the best tweaks and designs into your assortments on time to build customer loyalty
                            </p>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortText">
                            <h5>
                              Identify and fill <span>opportunity gaps</span> before the competition
                            </h5>
                            <p>
                              Review the competition's product categories and market performance and build a comprehensive assortment plan that factors in their missed opportunities and drives you ahead
                            </p>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortImg">
                            <img src={de6} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortImg">
                            <img src={de7} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortText">
                            <h5>
                              Drive sales and performance with a working <span>pricing strategy</span>
                            </h5>
                            <p>
                              Review the pricing architecture of your fiercest competitors and storm the market with a solid pricing strategy that’s sure to drive up your profit margin
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div style={{ background: "#FADCE4", padding: "10px" }}>
                    <div style={{ width: "70%", margin: "0 auto", padding: "50px 0px" }}>
                      <div className="planning">
                        <p className="pHead">Business Planning and Strategy</p>
                        <h2>
                          Reinforce business plans with solid strategies to drive growth
                        </h2>
                        <p className="planPara">
                          Reinforce your business plans with solid, data-backed strategies and grow your business to a new level
                        </p>
                      </div>
                    </div>

                  </div>
                  <div style={{ background: "white", padding: "10px" }}>
                    <div className="price">
                      <Row className="priceBox">
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="priceImg">
                            <img src={de8} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="priceText">
                            <h4>
                              Stand at the forefront of <span>profitable trends</span>
                            </h4>
                            <p>
                              Spot rising trends before the competition, incorporate the favorable ones into your assortment, and impress local and international markets
                            </p>
                          </div>
                          <div className="priceText">
                            <h4>
                              Bolster your <span>business plan</span> and avoid marketing pitfalls
                            </h4>
                            <p>
                              Strengthen your business decisions with accurate information about the market and your competition, avoid retail pitfalls, elevate department strategies, and trade confidently
                            </p>
                          </div>
                          <div className="priceText">
                            <h4>
                              Adopt <span>novel strategies</span> to meet demand without fail
                            </h4>
                            <p>
                              Create a winning business strategy with detailed retail analytics that's aimed at meeting customers' expectations
                            </p>
                          </div>
                          <div className="priceText">
                            <h4>
                              Recognize threats and <span>grab opportunities</span> with ease
                            </h4>
                            <p>
                              Obtain a detailed overview of your market and draw up a blueprint to resist external threats, bolster your strengths and grab opportunities
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div style={{ background: "#E5DCFA", padding: "10px" }}>
                    <div className="trading">
                      <p className="tradingHead">
                        TRADING-REPORTING
                      </p>
                      <h2>
                        Speed up trading and act before the competition
                      </h2>
                      <p className="tradingPara">
                        Spot and fill market gaps fast, capitalize on trading opportunities, and consistently meet demand, all whilekeeping a close eye on the competition
                      </p>
                    </div>
                  </div>
                  <div style={{ background: "white", padding: "10px" }}>
                    <div className="shop">
                      <Row gutter={[60, 60]} className="shopInner">
                        <Col xs={24} sm={24} md={24} lg={10} xl={10} style={{ padding: '0' }}>
                          <div className="shopText">
                            <Row>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h5>
                                  <span>Comp shop</span> worldwide at the  click of a button
                                </h5>
                                <p>
                                  Manually sifting through competitor's websites is a tedious and outdated process. With Trends Sight we, get a hold of competitor's products with just a click and craft winning strategies
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h5>
                                  Find and take advantage of <span>profitable opportunities</span> right away
                                </h5>
                                <p>
                                  Not only will you be able to instantly identify profitable shifts in the market and determine the right trends to buy into with us, but you'll also be able toensure the right products are restocked on shelves and assigned the best prices
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h5>
                                  Benefit from identified <span>competitive blind-spots</span>
                                </h5>
                                <p>
                                  Our platform allows you to find competitive blind-spots to capitalise with winning pricing strategies that drive sales and ramp up demand
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10} style={{ padding: '0' }}>
                          <div className="shopImg">
                            <img src={mg7} alt="" />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Merchandiser" key="3" className="Merchandiser">
                  <div className="me-head-one">
                    <h1>Maximize margins with market analytics and insights</h1>
                    <p>Review detailed market insights on attractive dashboards, create clear and
                      compelling reports, and overhaul your decision-making process.</p>
                  </div>
                  <Row gutter={[60, 60]} justify="space-between" className="me-head-two">
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} className="me-two-card">
                      <img src={me1} alt="" />
                      <h3>
                        Draw plans that hit your targets <span>100%</span> of the time
                      </h3>
                      <p>Gain detailed market insights and consistently hit all your sales targets.</p>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} className="me-two-card">
                      <img src={me2} alt="" />
                      <h3><span>Comp shop</span> the right away with accuracy and efficiency</h3>
                      <p>
                        Closely monitor your competition, spot opportunity gaps, and revamp your pricing and investment plans from the comfort of your workspace.
                      </p>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} className="me-two-card">
                      <img src={me3} alt="" />
                      <h3><span>Invest wisely</span> and set competitive prices that drive sales</h3>
                      <p>
                        Gain detailed market insights and consistently hit all your sales targets.
                      </p>
                    </Col>
                  </Row>
                  <div style={{ background: 'white', padding: "10px" }}>

                    <div className="assortment">
                      <div className="assortmentHead">
                        <p className="assortPlanning">
                          ASSORTMENT PLANNING
                        </p>
                        <h2 className="assortHeading">
                          Draw up commercially-driven assortments plans that capture the trends and, in turn, your customers
                        </h2>
                        <p className="assortPara">
                          Adapt the latest trends into your assortments to meet the ever-growing customer demands in today's fast-moving retail market
                        </p>
                      </div>
                      <Row className="assortCards">
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortText">
                            <h5>
                              <span>Craft assortments</span> that represent your market
                            </h5>
                            <p>
                              Do away with outdated styles and incorporate novel designs into your assortments to outdo the competition
                            </p>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortImg">
                            <img src={de4} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortImg">
                            <img src={de5} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortText">
                            <h5>
                              <span>Spot favourable trends</span> and ideal adjustments to your assortments
                            </h5>
                            <p>
                              Analyse rising trends, identify and ignore the fads, and incorporate the best tweaks and designs into your assortments on time to build customer loyalty
                            </p>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortText">
                            <h5>
                              Identify and fill <span>opportunity gaps</span> before the competition
                            </h5>
                            <p>
                              Review the competition's product categories and market performance and build a comprehensive assortment plan that factors in their missed opportunities and drives you ahead
                            </p>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortImg">
                            <img src={de6} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortImg">
                            <img src={de7} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="assortText">
                            <h5>
                              Drive sales and performance with a working <span>pricing strategy</span>
                            </h5>
                            <p>
                              Review the pricing architecture of your fiercest competitors and storm the market with a solid pricing strategy that’s sure to drive up your profit margin
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div style={{ background: "#DCF2FA", padding: "10px" }}>
                    <div style={{ width: "70%", margin: "0 auto", padding: "60px 0px" }}>
                      <div className="allocatin">
                        <p className="alloHead">ALLOCATION & DISTRIBUTION</p>
                        <h2>
                          Optimize your allocation strategy to meet demand and maximize sales
                        </h2>
                        <p className="alloPara">
                          Streamline your allocation and delivery processes for better inventory management, and keep supply parallel with demand, preventing markdowns and maximizing profit
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ background: "white", padding: "10px" }}>
                    <div className="tips">
                      <Row className="tipsBox">
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="tipsImg">
                            <img src={me4} alt="" />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="tipsText">
                            <h4>
                              Maintain <span>optimum</span> stock cover every cycle
                            </h4>
                            <p>
                              Perfect your allocation strategies to prevent unfavourable stock
                              <br />
                              covers and markdowns
                            </p>
                          </div>
                          <div className="tipsText">
                            <h4>
                              Improve <span>sales</span> and retain customers
                            </h4>
                            <p>
                              Improve sales and retain customers by providing the right products across different channels
                            </p>
                          </div>
                          <div className="tipsText">
                            <h4>
                              Make correct <span>allocations</span> from the get-go
                            </h4>
                            <p>
                              Peruse real-time market reports with accurate SKU information to avoid issues with assortment allocation
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div style={{ background: "#E5DCFA", padding: "10px" }}>
                    <div className="trading">
                      <p className="tradingHead">
                        TRADING-REPORTING
                      </p>
                      <h2>
                        Speed up trading and act before the competition
                      </h2>
                      <p className="tradingPara">
                        Spot and fill market gaps fast, capitalize on trading opportunities, and consistently meet demand, all whilekeeping a close eye on the competition
                      </p>
                    </div>
                  </div>
                  <div style={{ background: "white", padding: "10px" }}>
                    <div className="shop">
                      <Row className="shopInner">
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="shopText">
                            <Row>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h5>
                                  <span>Comp shop</span> worldwide at the  click of a button
                                </h5>
                                <p>
                                  Manually sifting through competitor's websites is a tedious and outdated process. With Trends Sight we, get a hold of competitor's products with just a click and craft winning strategies
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h5>
                                  Find and take advantage of <span>profitable opportunities</span> right away
                                </h5>
                                <p>
                                  Not only will you be able to instantly identify profitable shifts in the market and determine the right trends to buy into with us, but you'll also be able toensure the right products are restocked on shelves and assigned the best prices
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h5>
                                  Benefit from identified <span>competitive blind-spots</span>
                                </h5>
                                <p>
                                  Our platform allows you to find competitive blind-spots to capitalise with winning pricing strategies that drive sales and ramp up demand
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                          <div className="shopImg">
                            <img src={mg7} alt="" />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </section>
      </Content>
      <Footer />
      <Contact />
    </Layout>
  );
};

export default Solution;
