import React, { useState } from "react";
import "./PrivacyPolicy.scss";
import { PrivacyPolicy_Texts, CookiePolicy_Texts, DataProcessing_Texts } from "./texts";
import { TabSelectorPrivacyPolicy } from "../../atoms";
import { Tab_Selector_Privacy_Policy } from "../../types/index";

import { Row, Col, Tabs, Layout } from 'antd';
import Navbar from "../../molecules/Header/Header";
import Footer from "../../molecules/Footer/Footer";


export interface PrivacyPolicyProps {
  customCls?: string;
}

const { Content } = Layout;

const PrivacyPolicy = ({ customCls }: PrivacyPolicyProps) => {
  const [selectedtab, setSelectedtab] = useState(Tab_Selector_Privacy_Policy.Privacy_Policy);
  const switchTab = (tab_: Tab_Selector_Privacy_Policy) => {
    setSelectedtab(tab_);
  }
  return (
    <Layout style={{ background: "transparent" }}>
      <Navbar selectedKey="3" />
      <Content style={{ marginTop: 64, }}>
        <div className={`terms_condition ${customCls}`}>
          <div className={"top_heading"}>{
            selectedtab === Tab_Selector_Privacy_Policy.Privacy_Policy ? PrivacyPolicy_Texts.Heading : (
              selectedtab === Tab_Selector_Privacy_Policy.Cookie_Policy ? CookiePolicy_Texts.Heading :
                DataProcessing_Texts.Heading
            )
          }</div>
          <div className={"top_subheading"}>{
            selectedtab === Tab_Selector_Privacy_Policy.Privacy_Policy ? PrivacyPolicy_Texts.SubHeading : (
              selectedtab === Tab_Selector_Privacy_Policy.Cookie_Policy ? CookiePolicy_Texts.SubHeading :
                DataProcessing_Texts.SubHeading
            )
          }</div>
          <TabSelectorPrivacyPolicy onClick={switchTab} customCls={"tab_selector"} />
          {selectedtab === Tab_Selector_Privacy_Policy.Privacy_Policy && <><div className={"heading heading_one"}>
            {PrivacyPolicy_Texts.Heading_One}

          </div>
            <p>At Trendssight, accessible from www.trendssight.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Trendssight and how we use it.</p>

            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Trendssight. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the <a href="https://www.cookiepolicygenerator.com/privacy-policy-generator/">Privacy Policy Generator from CookiePolicyGenerator.com</a>.</p>

            <h2>Consent</h2>

            <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

            <h2>Information we collect</h2>

            <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
            <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
            <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>

            <h2>How we use your information</h2>

            <p>We use the information we collect in various ways, including to:</p>

            <ul>
              <li>Provide, operate, and maintain our website</li>
              <li>Improve, personalize, and expand our website</li>
              <li>Understand and analyze how you use our website</li>
              <li>Develop new products, services, features, and functionality</li>
              <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
              <li>Send you emails</li>
              <li>Find and prevent fraud</li>
            </ul>

            <h2>Log Files</h2>

            <p>Trendssight follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

            <h2>Cookies and Web Beacons</h2>

            <p>Like any other website, Trendssight uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>

            <p>For more general information on cookies, please read <a href="https://www.privacypolicyonline.com/what-are-cookies/">"What Are Cookies"</a>.</p>



            <h2>Advertising Partners Privacy Policies</h2>

            <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Trendssight.</p>

            <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Trendssight, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

            <p>Note that Trendssight has no access to or control over these cookies that are used by third-party advertisers.</p>

            <h2>Third Party Privacy Policies</h2>

            <p>Trendssight's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

            <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

            <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

            <p>Under the CCPA, among other rights, California consumers have the right to:</p>
            <p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
            <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
            <p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

            <h2>GDPR Data Protection Rights</h2>

            <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
            <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
            <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
            <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
            <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
            <p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
            <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

            <h2>Children's Information</h2>

            <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

            <p>Trendssight does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>


          </>}
          {selectedtab === Tab_Selector_Privacy_Policy.Cookie_Policy && <><div className={"heading heading_one"}>
          </div>

            <p>This is the Cookie Policy for Trends Sight, accessible from www.trendssight.com</p>

            <p><strong>What Are Cookies</strong></p>

            <p>As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.</p>

            <p><strong>How We Use Cookies</strong></p>

            <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</p>

            <p><strong>Disabling Cookies</strong></p>

            <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies. This Cookies Policy was created with the help of the <a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/">Cookies Policy Generator from CookiePolicyGenerator.com</a>.</p>
            <p><strong>The Cookies We Set</strong></p>

            <ul>


              <li>
                <p>Login related cookies</p>
                <p>We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.</p>
              </li>




              <li>
                <p>Forms related cookies</p>
                <p>When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.</p>
              </li>

              <li>
                <p>Site preferences cookies</p>
                <p>In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.</p>
              </li>

            </ul>

            <p><strong>Third Party Cookies</strong></p>

            <p>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</p>

            <ul>

              <li>
                <p>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.</p>
                <p>For more information on Google Analytics cookies, see the official Google Analytics page.</p>
              </li>









            </ul>

            <p><strong>More Information</strong></p>

            <p>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.</p>

            <p>For more general information on cookies, please read <a href="https://www.privacypolicyonline.com/what-are-cookies/">"What Are Cookies"</a>.</p>

            <p>However if you are still looking for more information then you can contact us through one of our preferred contact method: info [at] trendssight [dot] com</p>

            <ul>


            </ul>

          </>}
          {selectedtab === Tab_Selector_Privacy_Policy.Data_Processing &&
            <>
              <div className={"subheading subHeading_one"}>
                {DataProcessing_Texts.SubHeading_One}
              </div>
              <div className={"heading heading_two"}>
                {DataProcessing_Texts.Heading_Two}
              </div>
              <div className={"subheading subHeading_two"}>
                {DataProcessing_Texts.SubHeading_Two}
              </div>
              <div className={"heading heading_three"}>
                {DataProcessing_Texts.Heading_Three}
              </div>
              <div className={"subheading subHeading_three"}>
                {DataProcessing_Texts.SubHeading_Three}
              </div>
              <div className={"heading heading_four"}>
                {DataProcessing_Texts.Heading_Four}
              </div>
              <div className={"subheading subHeading_four"}>
                {DataProcessing_Texts.SubHeading_Four}
              </div>
              <div className={"heading heading_five"}>
                {DataProcessing_Texts.Heading_Five}
              </div>
              <div className={"subheading subHeading_five"}>
                {DataProcessing_Texts.SubHeading_Five}
              </div>
              <div className={"heading heading_six"}>
                {DataProcessing_Texts.Heading_Six}
              </div>
              <div className={"subheading subHeading_six"}>
                {DataProcessing_Texts.SubHeading_Six}
              </div>
              <div className={"heading heading_seven"}>
                {DataProcessing_Texts.Heading_Seven}
              </div>
              <div className={"subheading subHeading_seven"}>
                {DataProcessing_Texts.SubHeading_Seven}
              </div>
            </>
          }
        </div>
      </Content>
      <Footer />
    </Layout>
  );
};

export default PrivacyPolicy;
