import React, { useState } from "react";
import { BlueButton } from "../../atoms";
import "./Login.scss";
import { Login_text } from "./texts";
import { Eye } from "../../../assets/images";
import { Layout } from 'antd';
import Navbar from "../../molecules/Header/Header";

export interface LoginProps {
  customCls?: string;
  onLoginClick?: () => {};
  onSignUpClick?: () => {};
}

export enum LoginState {
  Login = "Login",
  Forgot_Password = "Forgot_Password",
}

const { Content } = Layout;

const Login = ({ customCls, onLoginClick, onSignUpClick }: LoginProps) => {
  const [email, setEmail] = useState(
    sessionStorage.getItem("trendsSightEmail") ?
      sessionStorage.getItem("trendsSightEmail") : ''
  );
  const [password, setPassword] = useState("");
  const [login_state, setLogin_State] = useState(LoginState.Login);
  const [showPass, setShowPass] = useState(false);

  const [loginClicked, setLoginClicked] = useState(false);

  const onForgotPasswordClick = () => {
    setLogin_State(LoginState.Forgot_Password);
    setLoginClicked(false);
  };

  const onBackToLoginClick = () => {
    setLogin_State(LoginState.Login);
    setLoginClicked(false);
  };

  const clickLogin = () => {
    setLoginClicked(true);
  }
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    <Layout style={{ background: "transparent" }}>
      <Navbar selectedKey="null" />
      <Content style={{ marginTop: 64, }}>
        <div className={`login_comp ${customCls}`}>
          <div className={`left_half ${login_state}`}>
            <div className={"left_heading"}></div>
            <div className={"left_text"}></div>
          </div>
          <div className={"right_half"}>
            <div className={"login_heading"}>{Login_text.Right_Login}</div>
            <div className={"login_section"}>
              <div className={"welcome_user"}>
                {login_state === LoginState.Login
                  ? Login_text.Right_Welcome
                  : Login_text.Forgot_password}
              </div>
              <div className={"enter_email_pass"}>
                {login_state === LoginState.Login
                  ? Login_text.Right_email_pass
                  : Login_text.Forgot_pass_instruction}
              </div>
              <input
                className={"email"}
                type={"email"}
                placeholder={"Email Address"}
                value={email ? email : ''}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
              {login_state === LoginState.Login && (
                <input
                  className={"password"}
                  type={showPass === false ? "password" : "text"}
                  placeholder={"Password"}
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              )}
              {login_state === LoginState.Login && (
                <img
                  src={Eye}
                  className={"show_pass"}
                  alt={"Show"}
                  onMouseDown={() => {
                    setShowPass(true);
                  }}
                  onMouseUp={() => {
                    setShowPass(false);
                  }}
                  onMouseLeave={() => {
                    setShowPass(false);
                  }}
                ></img>
              )}
              {login_state === LoginState.Login && <div className={'remember_me_grp'}>
                <input className={'remember_me_box'} type={'checkbox'}></input>
                <span className={'remember_me'}>{"Remember Me"}</span>
              </div>}
              <div className={`incorrectCred ${loginClicked ? 'show' : 'hide'} ${login_state === LoginState.Login ? 'login' : 'forgot'}`}>
                {!re.test(String(email).toLowerCase()) ? <p style={{ color: "red" }} >Please Enter Valid Email</p> : login_state === LoginState.Login ? "Please enter correct Username and Password" : "If you email adress is found a reset link has been sent"}
              </div>
              <div
                className={"forgot_pass"}
                onClick={() => {
                  login_state === LoginState.Login
                    ? onForgotPasswordClick()
                    : onBackToLoginClick();
                }}
              >
                {login_state === LoginState.Login
                  ? Login_text.Right_ForgotPass
                  : Login_text.Back_To_Login}
              </div>
              <BlueButton
                label={login_state === LoginState.Login ? "Login" : "Send Link"}
                customCls={`login_button ${login_state === LoginState.Login ?
                    (email !== "" && password !== "" ? "active" : "disable") :
                    (email !== "" ? "active" : "disable")
                  }`}
                onClick={clickLogin}
              ></BlueButton>
            </div>
            <div className={"signup_section"}>
              <span className={"first"}>{Login_text.Right_Signup1}</span>
              <span className={"second"}>{Login_text.Right_Signup2}</span>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default Login;
