import React from "react";
import "./App.scss";

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Layout } from 'antd';
import 'antd/dist/antd.css';

import ScrollToTop from "./scrollToTop/scrollToTop";

import Home from "./components/template/Home/Home";
import Solution from "./components/template/Solution/Solution";
import Sustainability from "./components/template/Sustainability/Sustainability";
import Login from "./components/template/Login/Login";
import Demo from "./components/template/BookDemo/BookDemo";
import Contact from "./components/template/Contact/Contact";
import TermsAndCondition from "./components/template/TermsAndCondition/TermsAndCondition";
import Privacy from "./components/template/PrivacyPolicy/PrivacyPolicy";

const { Header, Content } = Layout;


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/solution" component={Solution} />
        <Route path="/designer" component={Solution} />
        <Route path="/management" component={Solution} />
        <Route path="/merchandiser" component={Solution} />
        <Route path="/sustainability" component={Sustainability} />
        <Route path="/login" component={Login} />
        <Route path="/demo" component={Demo} />
        <Route path="/termsAndCondition" component={TermsAndCondition} />
        <Route path="/privacy" component={Privacy} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
