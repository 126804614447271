import React, { useState } from "react";
import "./TabSelectorPrivacyPolicy.scss";
import { Tab_Selector_Privacy_Policy } from "../../types/index";

export interface TabSelectorProps {
  customCls?: string;
  selectedTab?: Tab_Selector_Privacy_Policy;
  onClick?: (tab_: Tab_Selector_Privacy_Policy) => void;
}

const TabSelectorPrivacyPolicy = ({
  customCls,
  selectedTab = Tab_Selector_Privacy_Policy.Privacy_Policy,
  onClick = (tab_: Tab_Selector_Privacy_Policy) => {},
}: TabSelectorProps) => {
  const [selectedTabState, setselectedTabState] = useState(
    Tab_Selector_Privacy_Policy.Privacy_Policy
  );

  const switchTab = (tab: Tab_Selector_Privacy_Policy) => {
    setselectedTabState(tab);
    onClick(tab);
  };

  return (
    <div className={`Tab_Selector_Privacy_Policy ${customCls}`}>
      <span
        onClick={() => {
          switchTab(Tab_Selector_Privacy_Policy.Privacy_Policy);
        }}
        className={`tab_ privacy_policy ${
          selectedTabState === Tab_Selector_Privacy_Policy.Privacy_Policy
            ? "selected"
            : ""
        }`}
      >
        {Tab_Selector_Privacy_Policy.Privacy_Policy}
        {selectedTabState === Tab_Selector_Privacy_Policy.Privacy_Policy ? (
          <div className={"blue_bar"}></div>
        ) : (
          ""
        )}
      </span>
      <span
        onClick={() => {
          switchTab(Tab_Selector_Privacy_Policy.Cookie_Policy);
        }}
        className={`tab_ cookie_management ${
          selectedTabState === Tab_Selector_Privacy_Policy.Cookie_Policy
            ? "selected"
            : ""
        }`}
      >
        {Tab_Selector_Privacy_Policy.Cookie_Policy}
        {selectedTabState === Tab_Selector_Privacy_Policy.Cookie_Policy ? (
          <div className={"blue_bar"}></div>
        ) : (
          ""
        )}
      </span>
      <span
        onClick={() => {
          switchTab(Tab_Selector_Privacy_Policy.Data_Processing);
        }}
        className={`tab_ data_processing ${
          selectedTabState === Tab_Selector_Privacy_Policy.Data_Processing
            ? "selected"
            : ""
        }`}
      >
        {Tab_Selector_Privacy_Policy.Data_Processing}
        {selectedTabState === Tab_Selector_Privacy_Policy.Data_Processing ? (
          <div className={"blue_bar"}></div>
        ) : (
          ""
        )}
      </span>
    </div>
  );
};

export default TabSelectorPrivacyPolicy;
