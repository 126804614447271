export const Login_text = {
  Left_Heading: "Lorum Ipsum",
  Left_Text:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non magna\nproin lectus morbi cras. Eu eu enim scelerisque praesent congue velit.\nLectus a in et nibh. Ut sed nisl, quis quam. Mauris enim ultricies non\nlorem ipsum sem etiam.",
  Right_Login: "LOGIN",
  Right_Welcome: "Welcome Back User!",
  Right_email_pass: "Enter email & pasword below to login into\nyour account",
  Right_ForgotPass: "Forgot Password?",
  Right_Signup1: "Don’t have an account?",
  Right_Signup2: "Get Started",
  Forgot_password: "Forgot Password",
  Forgot_pass_instruction:
    "Please enter your Email below so\ncan send the reset link there",
  Back_To_Login: "Back to login",
};
