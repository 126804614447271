import React, { useState } from "react";
import "./BookDemoBar.scss";
import BlueButton from "../BlueButton";

export interface BookDemoBarProps {
  customCls?: string;
  placeHolder?: string;
  onClick?: () => void;
}

const BookDemoBar = ({
  customCls,
  placeHolder = "Email Address",
  onClick = () => {},
}: BookDemoBarProps) => {
  const [email, setEmail] = useState("");
  const btnClick = () => {
    console.log("Book a Demo for " + email);
    setEmail("");
    onClick();
    const email_:HTMLInputElement = document.querySelectorAll('.bookDemo_')[0] as HTMLInputElement;
    sessionStorage.setItem("trendsSightEmail", email_.value);
    (window.location.href = window.location.origin + "/bookDemo");
  };
  return (
    <div className={`book_demo_bar ${customCls}`}>
      <input
        className={email === "" ? "bookDemo_ email_ip_placeHolder" : "bookDemo_ email_ip"}
        type={"email"}
        placeholder={placeHolder}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      ></input>
      <BlueButton
        label={"Book a Demo"}
        customCls={`book_demo_btn is_active`}
        onClick={() => btnClick()}
      ></BlueButton>
    </div>
  );
};

export default BookDemoBar;
