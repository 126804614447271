import React from 'react';
import './Sustainability.scss';
import { Row, Col, Tabs, Layout } from 'antd';

import Navbar from "../../molecules/Header/Header";
import Footer from "../../molecules/Footer/Footer";
import Contact from "../Contact/Contact";

import sl1 from './assets/images/img1.png';

const { Content } = Layout;

const { TabPane } = Tabs;

const Sustainability = () => {
  return (
    <Layout style={{ background: "transparent" }}>
      <Navbar selectedKey="3" />
      <Content style={{ marginTop: 64, }}>
        <section className="sustainabilitySection">
          <div className="Sustainability">
            <div className="susHeader">
              <div className="susHeader-content">
                <h1>
                  Our commitment
                </h1>
                <p>
                  At Trends Sight, we believe that meeting our present needs is just as important as preserving the capability of our future generations to meet theirs. That’s why sustainability has been at the core of our business processes since our inception. Our internal and external policies incorporate social, economic, and environmental goals into our investment decision-making process.
                  <br />
                  <br />
                  We are committed to becoming Carbon Negative by the end of 2021 and Climate Positive by the end of 2022
                </p>
              </div>
            </div>

            <div className="commitments">
              <div className="social-heading">
                <h2>Social commitments</h2>
              </div>
              <Tabs tabPosition="left" className="commitTabs">
                <TabPane tab="Going remote" key="1" className="commitPane">
                  <p>
                    Remote working is becoming the new norm. Companies like Ford, Facebook, Amazon, and GitHub have unveiled plans to transition to remote working fully. Remote working offers a plethora of benefits—for some, it saves up to 15 hours of commute time every week, fostering a better work-life balance.
                    <br />
                    <br />
                    Having fashioned remote working into a core tenet of our workforce strategy, we use the global talent pool to find top talents who embrace our ethics and allow us to provide our clients better support. We offer our staff who prefer to commute to work a cycle to work scheme.
                  </p>

                </TabPane>
                <TabPane tab="Work Flexibility" key="2" className="commitPane">
                  <p>
                    Our team is a global one, with employees from many countries around the world, and they bring to our workforce a mix of cultures and religions. Many of them are parents and have family responsibilities. In addition, our working hours aren’t rigidly fixed. We allow non-client-facing staff to work fully flexible hours, enabling them to balance multiple priorities in and out of work. For our client-facing staff, we rearrange hours to provide the required cover, allowing them to take any time off they require. Our flexibility also extends into considering applicants for part-time positions, particularly those who cannot work full-time.
                  </p>
                </TabPane>
                <TabPane tab="Career Progression" key="3" className="commitPane">
                  <p>
                    Our employees' personal and professional growth is crucial to delivering excellent value. That is why we are dedicated to providing our employees with training in formal development that responds to their ever-changing needs.
                  </p>
                </TabPane>
                <TabPane tab="Stress" key="4">
                  <p>
                    We don’t provide this! Instead, we are committed to fostering a work culture that is serious but relaxed. Our employees have a significant say in how the day-to-day business is run; it keeps our operations running smoothly and the work environment stress-free.
                  </p>
                </TabPane>
              </Tabs>
            </div>

            <div className="economic">
              <div>
                <h2>
                  Economic and Environmental commitments
                </h2>
              </div>
              <Row justify="space-between" align="middle" className="economic-row">
                <Col span={11}>
                  <p>
                    The fashion industry is not known for its sustainability, but that is changing fast. The biggest impact Trends Sight provides is our solution. We help brands better understand demand levels throughout a product’s life cycle. It allows them to make sure that production levels remain in line with demand and that products don’t have to be disposed of. Environmentally, this is key—it reduces the adverse impact that overproduction has on the environment, which is far worse than eventual disposal.
                    <br />
                    <br />
                    While our current solution focuses heavily on demand-level market intelligence, we are developing other products that help brands be more sustainable.

                  </p>
                </Col>
                <Col span={11}>
                  <img src={sl1} alt="" />
                </Col>
              </Row>
            </div>

          </div>
        </section>
      </Content>
      <Footer />
      <Contact />
    </Layout>
  );
};

export default Sustainability;
