import React, { ChangeEvent, useState } from "react";
import { BlueButton } from "../../atoms";
import "./BookDemo.scss";
import { BookDemo_text } from './texts';
import { Link } from "react-router-dom";

import { Layout, Menu } from 'antd';

import Navbar from "../../molecules/Header/Header";
import Footer from "../../molecules/Footer/Footer";

export interface BookDemoProps {
  customCls?: string;
}


const { Content } = Layout;

const BookDemo = ({ customCls }: BookDemoProps) => {
  const [email, setEmail] = useState(
    sessionStorage.getItem("trendsSightEmail") ?
      sessionStorage.getItem("trendsSightEmail") : ''
  );

  return (
    <Layout style={{ background: "transparent" }}>
      <Navbar selectedKey="null" />
      <Content style={{ marginTop: 64 }}>
        <div className={`book_a_demo ${customCls}`}>
          <div className={"curved_backdrop"}></div>
          <div className={'form'}>
            <div className={'heading'}>{BookDemo_text.Heading}</div>
            <div className={'text'}>{BookDemo_text.Text}</div>
            <div className={'demo_form'}>
              <form action="">
                <div className={'first_row'}>
                  <input
                    className={'firstName'}
                    placeholder={'First name'}
                    type={'text'}
                  />
                  <input
                    className={'lastName'}
                    placeholder={'Last name'}
                    type={'text'}
                  />
                </div>
                <div className={'second_row'}>
                  <input
                    className={'email'}
                    placeholder={'Email Address'}
                    type={'email'}
                  />
                  <input
                    className={'phoneNumber'}
                    placeholder={'Phone Number'}
                    type={'text'}
                  />
                </div>
                <div className={'third_row'}>
                  <input
                    className={'company_website'}
                    placeholder={"Company's Website"}
                    type={'url'}
                  />
                </div>
                <div className={'fourth_row'}>
                  <textarea
                    className={'elaborate_topic'}
                    placeholder={"Elaborate your topic"}
                  />
                </div>
                <div className={'fifth_row'}>
                  <Link to="./">
                    <button className="submitBtn" type="reset">SUBMIT</button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Content>
      <Footer />
    </Layout>
  );
};

export default BookDemo;
