export enum TAB_SELECTOR {
  DESIGNER = "DESIGNERS",
  MANAGEMENT = "MANAGEMENT",
  MERCHANDISERS = "MERCHANDISERS",
}

export enum Tab_Selector_Privacy_Policy {
  Privacy_Policy = "PRIVACY POLICY",
  Cookie_Policy = "COOKIE POLICY",
  Data_Processing = "DATA PROCESSING",
}
