import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { Row, Col, Button } from 'antd';
import {
  LinkedinFilled,
  InstagramOutlined,
  createFromIconfontCN

} from '@ant-design/icons';

import trendLogo from "../../../assets/images/LogoNew.png";

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
});



const Footer = () => {

  return (
    <div className="footer">
      <div className="footerContent">
        <div className="footerInner">
          <Row className="footerMainRow">
            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
              <div>
                <div className="footerHeading">
                  <img src={trendLogo} alt="" className="footerLogo" />
                </div>
                <div className="socialLinks">
                  <Row>
                    <Col span={5}>
                      <LinkedinFilled className="footerIcon" />
                    </Col>
                    <Col span={5}>
                      <InstagramOutlined className="footerIcon" />
                    </Col>
                    <Col span={5}>
                      <IconFont type="icon-facebook" className="footerIcon" />
                    </Col>
                    <Col span={5}>
                      <IconFont type="icon-twitter" className="footerIcon" />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
              <div>
                <div className="footerHeading">
                  <h5>Pages</h5>
                </div>
                <ul>
                  <li>
                    <Link to="./home">
                      <a href="">Home</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="./sustainability">
                      <a href="">Sustainability</a>
                    </Link>
                  </li>
                  <li>
                    <a href="">Contact</a>
                  </li>
                  <li>
                    <Link to="./demo">
                      <a href="">Demo</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
              <div>
                <div className="footerHeading">
                  <h5>Solutions</h5>
                </div>
                <ul>
                  <li>
                    <Link to="./designer">
                      <a href="">Designers</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="./management">
                      <a href="">Management</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="./merchandiser">
                      <a href="">Merchandisers & Buyers</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <div className="footerEndLine">
            <div>
              <p>® 2021 Trends Sight</p>
            </div>
            <div>
              <Link to="./termsAndCondition">
                <a href="">Terms & Condition</a>
              </Link>
              <Link to="./privacy">
                <a href="">Privacy Policy</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
